import React from 'react';

import NavLinkMobile from './NavLink/NavLinkMobile';
import styled from 'styled-components';

const StyledNavLinks = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.media.fablet} {
    flex-direction: row;
  }
`;

const NavLinksMobile = ({ clicked }) => {
  return (
    <StyledNavLinks>
      <NavLinkMobile to="/" clicked={() => clicked()}> Start </NavLinkMobile>
      <NavLinkMobile to="/stronywww" clicked={() => clicked()}> Strony WWW </NavLinkMobile>
      <NavLinkMobile to="/socialmedia" clicked={() => clicked()}> Social Media </NavLinkMobile>
      <NavLinkMobile to="/studiograficzne" clicked={() => clicked()}> Studio Graficzne </NavLinkMobile>
      <NavLinkMobile to="/realizacje" clicked={() => clicked()}> Realizacje </NavLinkMobile>
      <NavLinkMobile to="/kontakt" clicked={() => clicked()}> Kontakt </NavLinkMobile>
    </StyledNavLinks>
  );
};

export default NavLinksMobile;
