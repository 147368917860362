// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-socialmedia-js": () => import("./../../../src/pages/socialmedia.js" /* webpackChunkName: "component---src-pages-socialmedia-js" */),
  "component---src-pages-stronywww-js": () => import("./../../../src/pages/stronywww.js" /* webpackChunkName: "component---src-pages-stronywww-js" */),
  "component---src-pages-studiograficzne-js": () => import("./../../../src/pages/studiograficzne.js" /* webpackChunkName: "component---src-pages-studiograficzne-js" */)
}

